import React from 'react';
import './CouponItem.css';

const CouponItem = ({ coupon, onRedeem }) => {
    return (
        <div className="coupon-item" style={{ backgroundImage: `url(${coupon.image})` }}>
            <div className="coupon-content">
                <h3>{coupon.title}</h3>
                <p>{coupon.description}</p>
                <button onClick={() => onRedeem(coupon)} disabled={coupon.redeemed}>
                    {coupon.redeemed ? 'Redeemed' : 'Redeem'}
                </button>
            </div>
        </div>
    );
};

export default CouponItem;
