import React from 'react';
import CouponItem from './CouponItem';

const CouponList = ({ coupons, onRedeem }) => {
    return (
        <div>
            {coupons.map((coupon) => (
                <CouponItem key={coupon.id} coupon={coupon} onRedeem={onRedeem} />
            ))}
        </div>
    );
};

export default CouponList;
