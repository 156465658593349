import React, { useState, useEffect } from 'react';
import CouponList from './components/CouponList';
import CouponForm from './components/CouponForm';
import CouponItem from './components/CouponItem';
import emailjs from 'emailjs-com';
import './App.css';

const App = () => {
    const predefinedCoupons = [
        { id: 1, title: '2 Nights in New York City', description: 'Enjoy a 2-night stay in NYC!', redeemed: false, image: '/images/nyc.jpg' },
        { id: 2, title: '4 Nights in Puerto Rico', description: 'Relax for 4 nights in Puerto Rico!', redeemed: false, image: '/images/puerto_rico.jpg' },
        { id: 3, title: '1 Day Spa Massage', description: 'Rejuvenate with a 1-day spa massage!', redeemed: false, image: '/images/spa.jpg' }
    ];

    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        document.body.style.backgroundImage = `url('/images/background.jpg')`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundRepeat = 'no-repeat';

        return () => {
            document.body.style.backgroundImage = '';
        };
    }, []);

    const addCoupon = (coupon) => {
        setCoupons([...coupons, { ...coupon, id: Date.now(), redeemed: false }]);
    };

    const redeemCoupon = (coupon) => {
        sendEmail(coupon);
        alert(`Coupon Redeemed: ${coupon.title}`);
        setCoupons(coupons.map(c => c.id === coupon.id ? { ...c, redeemed: true } : c));
    };

    const sendEmail = (coupon) => {
        const templateParams = {
            couponTitle: coupon.title,
            couponDescription: coupon.description,
            userEmail: 'kaadamohamed@gmail.com'
        };

        emailjs.send('service_dwx42be', 'template_olie97a', templateParams, 'rEoMQrYyY23fWwIyQ')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (error) => {
                console.log('FAILED...', error);
            });
    };

    return (
        <div>
            <h1>Birthday Coupon App</h1>
            <CouponForm onAddCoupon={addCoupon} />
            <CouponList coupons={coupons} onRedeem={redeemCoupon} />
            <h2>Predefined Coupons</h2>
            <div>
                {predefinedCoupons.map((coupon) => (
                    <CouponItem key={coupon.id} coupon={coupon} onRedeem={redeemCoupon} />
                ))}
            </div>
        </div>
    );
};

export default App;
