import React, { useState } from 'react';

const CouponForm = ({ onAddCoupon }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddCoupon({ title, description, id: Date.now() });
        setTitle('');
        setDescription('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <input 
                type="text" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                placeholder="Coupon Title" 
                required 
            />
            <textarea 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
                placeholder="Coupon Description" 
                required 
            ></textarea>
            <button type="submit">Add Coupon</button>
        </form>
    );
};

export default CouponForm;
